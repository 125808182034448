import "./App.css";
import React from "react";
import { Checkout } from "./screens/Checkout";
import { UserProvider } from "./contexts/user";

function App() {
  return (
      <UserProvider>
        <Checkout />
      </UserProvider>
  );
}

export default App;
