import React, { useState, useRef, useEffect } from "react";
import * as FirestoreService from "../services/firestore";
import ClipLoader from "react-spinners/ClipLoader";
import "../App.css";
import { UserContext } from "../contexts/user";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Chip, Divider } from "@mui/material";

export function Product({ product }) {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const paypalRef = useRef();
  const [state, dispatch] = React.useContext(UserContext);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  const card = priceDetails ? (
    <React.Fragment>
      <CardContent sx={{ backgroundColor: "#222222" }}>
        <Typography variant="h6" component="div">
          {state.email}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {priceDetails.discount} discount!
        </Typography>
        <Typography variant="h5" component="div">
          ${priceDetails.salePrice}
        </Typography>
        <Typography variant="body2">
          <s>${priceDetails.actualPrice}</s>
        </Typography>
      </CardContent>
    </React.Fragment>
  ) : (
    <></>
  );

  useEffect(() => {
    FirestoreService.authenticateAnonymously()
      .then(() => {
        if (!priceDetails) {
          FirestoreService.getPrices()
            .then((prices) => {
              if (prices.exists) {
                setError(null);
                setPriceDetails(prices.data());
              } else {
                setError("prices-not-found");
              }
            })
            .catch(() => setError("prices-get-fail"));
        }
      })
      .catch((err) => {
        setError("anonymous-auth-failed");
      });
  }, [priceDetails]);

  useEffect(() => {
    if (priceDetails) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    currency_code: "USD",
                    value: priceDetails.salePrice,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            FirestoreService.makeUserPro(state.email, order);
            setPaidFor(true);
          },
          onError: (err) => {
            setError(err);
            console.error(err);
          },
        })
        .render(paypalRef.current);
      setLoading(false);
    }
  }, [product.description, priceDetails]);

  if (paidFor) {
    return (
      <div className="App">
        <Typography variant="h6" component="div" sx={{ padding: 2 }}>
          Congrats, {state.email} is now a Pro user!
        </Typography>
      </div>
    );
  }

  return priceDetails ? (
    <div>
      <Card variant="outlined">{card}</Card>
      {error && <div>Uh oh, an error occurred! {error.message}</div>}
      <Divider className="paddingTop"></Divider>
      <div className="paddingTop" ref={paypalRef} />
    </div>
  ) : (
    <div className="App">
      <ClipLoader
        className="App-loader"
        color="#000000"
        loading={loading}
        css={"Loading"}
        size={150}
      />
    </div>
  );
}
